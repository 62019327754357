.bh__feature-container__feature{
    /*width: 100%;currently removed */
    display:flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column; /*made change here*/
    margin: 1rem;
}

.bh__feature-container__feature-title{
    flex:1;
    max-width: 250px;
    margin-right: 2rem;
}
.drop{
    position: relative;
    top: 13px;
}

.bh__feature-container__feature-title h1{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 312% */

    letter-spacing: -0.04em;

    color: #FFFFFF;
}
.bh__feature-container__feature-title div{
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0 4px 4px rgba(0,0,0,0.25);
    margin-bottom: 0.25rem;
}
.bh__feature-container__feature-text{
    flex:2;
    max-width: 390px;
    display:flex;
}
.bh__feature-container__feature-text p{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* or 188% */
    color: #81AFDD;
}

@media screen and (max-width: 900px){
    .bh__feature-container__feature-title h1{
        font-size: 14px;
        line-height: 22px;
        font-weight:bolder;
    }
    .bh__feature-container__feature-title p{
        font-size: 12px;
        line-height: 20px;
    }
    .bh__feature-container__feature{
        margin: 1rem 0;
    }
    /*allowing scroll feature for mobile phones*/
    .bh__feature-container__feature-text p{
        height:250px; /*temporary may need to change*/
        width:200px; /*temporary may need to change*/
        overflow-x: hidden;
        overflow-y: auto; /*temporary may need to change*/
    }
}