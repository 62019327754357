.bh__brand{
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 50px;
    width: calc(100% - 20px); /* Set the width to be the total width of the images plus padding */
}

.bh__brand div{
    flex: 1;
    max-width: 150px;
    min-width: 120px;
    margin: 1rem;
    margin-right: 10rem;
    display:flex;
    justify-content: center;
    align-items: center;
}
.bh__brand-marquee{
    width:100%;
    height:200px;
    overflow: hidden;
}