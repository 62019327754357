.bh__functionality{
    display: flex;
    flex-direction: column;

    padding: 2rem;
    background: var(--color-footer);
    background-image: url('../../assets/badgeratbar.png');
    /* Shifts the image 20px down and 40px to the right */
    background-position: -100px -40px; /* Shifts the image 20px down and 40px to the right */
    background-repeat: none;
     /* Shifts the image 20px down and 40px to the right */
    /* ff 3.6+ */
    /*background:-moz-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    /*background:-webkit-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* opera 11.10+ */
    /*background:-o-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* ie 10+ */
    /*background:-ms-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* global 92%+ browsers support */
    /*background:radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /*filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));*/
}
.bh__functionality-image{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    
    
}
.bh__functionality-feature{
    display: flex;
    
}
.bh__functionality-feature .bh__feature-container__feature{
    margin: 0;
}

.bh__functionality-feature .bh__feature-container__feature-text{
    max-width: 700px;
}

.bh__functionality-heading{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin:4rem 0 2rem;
}
.bh__functionality-heading h1{
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    max-width: 510px;
    font-family: var(--font-family);
}
.bh__functionality-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 2rem;
}

@media screen and (max-width: 850px) {
    .bh__functionality-heading {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        
    }
    .bh__functionality-heading h1{
        margin-bottom: 0rem;
    }
    /*getting rid of margin on phones*/
    .section_margin{
        margin:0;
    }
    .bh__functionality-container{
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        justify-content: center;
        align-items: center;
    }
    
    .bh__functionality-container{
        justify-content: center;
    }
    .bh__functionality-feature{
        justify-content: center;
    }
    
}

@media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    .bh__functionality-feature .bh__functionality-container__feature {
        flex-direction: column;
    }

    /* Customizing feature component as per needs */
    .bh__functionality-feature .bh__functionality-container_feature-text {
        margin-top: 0.5rem;
    }

    .bh__functionality-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .bh__functionality-container .bh__functionality-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }
}