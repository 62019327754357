.bh__form{
    max-width: 500px;
    border: 2px solid #f68383;
    padding: 2rem;
}
.bh__form label{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* or 188% */
    color: #81AFDD;
}
#thankyou{
    color: #ffffff;
    font-family: monospace;
    font-size: 18px;
}